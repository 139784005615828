<script setup>
import MainService from './service/mainService';
import { useProductsStore } from './stores/products.store';
import Spinner from './components/Spinner.vue';
async function getData() {
    await MainService.handleSession();
    useProductsStore().fetchVersionAndUpdateProducts();
    console.log('App.vue - getData - MainService.handleSession()');
}
getData();
</script>

<template>
<Spinner />
<RouterView />
</template>
